


























































































































































































































































































import {Component, Vue} from 'vue-property-decorator';
import {namespace} from "vuex-class";
import ITRQ_DTO, {ITRQDocumentDTO} from "@/dto/request/itrq/ITRQ_DTO";
import IndividualTaxationService from "@/services/request/IndividualTaxationService";
import {ITRQDocumentType} from "@/constants/request/ITRQDocumentType";
import FileMetaDTO from "@/dto/files/FileMetaDTO";
import {ITRQStatus} from "@/constants/request/RequestsStatuses";
import {MaritalStatus} from "@/dto/taxation/individual/MaritalStatus";
import AuthRequestModal from "@/components/request/itrq/AuthRequestModal.vue";

const AppModule = namespace("App");

@Component({
	computed: {
		ITRQStatus() {
			return ITRQStatus
		},
		MaritalStatus() {
			return MaritalStatus
		},
		dt() {
			return ITRQDocumentType
		}
	}
})
export default class EmployeeITRQ extends Vue {

	@AppModule.Action
	private startLoading!: () => void

	@AppModule.Action
	private stopLoading!: () => void

	private questionnaire: ITRQ_DTO | null = null;

	private documents: Record<ITRQDocumentType, FileMetaDTO | null> = {} as Record<ITRQDocumentType, FileMetaDTO | null>;
	private irsLetters: Array<ITRQDocumentDTO> = [];

	get id(): number {
		return Number.parseInt(this.$route.params.id);
	}

	mounted() {
		this.loadQuestionnaire();
	}

	get isItrqFilled(): boolean {
		return [ITRQStatus.CONFIRMED, ITRQStatus.PENDING_FOR_CONFIRMATION, ITRQStatus.COMPLETED, ITRQStatus.PENDING_FOR_PANDADOC_CONFIRMATION, ITRQStatus.PREPARED].includes(this.q.status)
	}

	loadQuestionnaire() {
		this.startLoading();
		return IndividualTaxationService.getById(this.id).then(
			ok => {
				this.initDocuments(ok.data.documents);
				this.questionnaire = ok.data;
				this.stopLoading()
			},
			err => {
				this.stopLoading();
				console.log(JSON.stringify(err));
			}
		)
	}

	initDocuments(docs: Array<ITRQDocumentDTO>) {
		this.irsLetters = [];
		docs.forEach(d => {
			if (d.type == ITRQDocumentType.IRS_LETTER) {
				this.irsLetters.push(d);
			} else {
				this.documents[d.type] = d.file;
			}
		});
	}

	get isSent(): boolean {
		return this.q.status == ITRQStatus.SENT;
	}

	get q(): ITRQ_DTO {
		return this.questionnaire!;
	}

	d(type: ITRQDocumentType): FileMetaDTO | null {
		return this.documents[type];
	}

	did(type: ITRQDocumentType): number {
		return this.documents[type]!.id;
	}

	get isSigned(){
		return [ITRQStatus.CONFIRMED, ITRQStatus.BEING_PREPARED, ITRQStatus.PREPARED, ITRQStatus.COMPLETED].includes(this.q.status);
	}

	get getStatus(): string{
		switch (this.q.status){
			case ITRQStatus.SENT: return "The questionnaire has been sent to the customer"
			case ITRQStatus.OPENED: return "The questionnaire has been opened by the customer"
			case ITRQStatus.BEING_FILLED_OUT: return  "The questionnaire is being filled out by the customer"
			case ITRQStatus.PENDING_FOR_CONFIRMATION: return  "Pending for primary confirmation"
			case ITRQStatus.PENDING_FOR_PANDADOC_CONFIRMATION: return  "Pending for signing by the customer"
			case ITRQStatus.CONFIRMED: return  "The customer has signed the confirmation document"
			case ITRQStatus.BEING_PREPARED: return "Return is being prepared"
			case ITRQStatus.PREPARED: return "Return has been prepared"
			case ITRQStatus.COMPLETED: return "The request has been closed"
			default: return this.q.status
		}
	}

	startPreparation(){
		this.questionnaire!.status = ITRQStatus.BEING_PREPARED;
		this.startLoading();
		this.save();
	}

	closeRequest(){
		this.questionnaire!.status = ITRQStatus.COMPLETED;
		this.save();
	}

	save(){
		this.startLoading();
		IndividualTaxationService.saveById(this.id, this.q).then(
			ok => {
				this.stopLoading();
				this.loadQuestionnaire();
			},
			err => {
				console.log(JSON.stringify(err));
				this.stopLoading();
			}
		)
	}

	resetToConfirmation(){
		this.questionnaire!.status = ITRQStatus.PENDING_FOR_CONFIRMATION;
		this.save();
	}

	private openAuthModal() {
		this.$modal.show(
			AuthRequestModal,
			{
				itrqId: this.id,
				customerName: this.q.firstName + " " + this.q.lastName
			}
		)
	}

}
