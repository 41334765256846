












































import {Component, Prop, Vue} from "vue-property-decorator";
import PortalSelect from "@/components/common/PortalSelect.vue";
import AuthFormPayload from "@/dto/archive/AuthFormPayload";
import SelectOption from "@/components/common/SelectOption";
import FileUpload from "@/components/common/FileUpload.vue";
import FileMetaDTO from "@/dto/files/FileMetaDTO";
import Workspaces from "@/state/Workspaces";
import {namespace} from "vuex-class";
import {ifValid} from "@/utils/ComponentUtils";
import IndividualTaxationService from "@/services/request/IndividualTaxationService";
import RouteNames from "@/router/RouteNames";
import ErrorLocalePathUtils from "@/utils/ErrorLocalePathUtils";

const AppModule = namespace("App");

@Component({
	components: {FileUpload, PortalSelect}
})
export default class AuthRequestModal extends Vue {

	private message = "";

	private successful = false;

	@AppModule.Action
	private startLoading!: () => void

	@AppModule.Action
	private stopLoading!: () => void

	@Prop()
	private itrqId!: number

	@Prop()
	private customerName!: string

	private payload: AuthFormPayload = new AuthFormPayload();

	private file: FileMetaDTO | null = null;

	public mounted(): void {
		this.payload.itrqId = this.itrqId;
	}

	private onFileLoaded(files: FileMetaDTO[]): void {
		this.file = files[0]
		this.payload.fileMetaId = files[0].id
	}

	generateName(): string {
		return this.payload.documentSubType
			+ '_' + this.customerName
			+ '_' + new Date().getTime();
	}

	private buildOptions(): Array<SelectOption> {
		return [
			SelectOption.builder().title("TR_579_IT_2023").value("TR_579_IT_2023").build(),
			SelectOption.builder().title("FORM_8879_2023").value("FORM_8879_2023").build(),
			SelectOption.builder().title("FORM_8453_CALIFORNIA_2023").value("FORM_8453_CALIFORNIA_2023").build(),
			SelectOption.builder().title("FORM_8453_MASSACHUSETTS_2023").value("FORM_8453_MASSACHUSETTS_2023").build(),
			SelectOption.builder().title("FORM_VA_8879_VIRGINIA_2023").value("FORM_VA_8879_VIRGINIA_2023").build(),
			SelectOption.builder().title("FORM_EL101_MARYLAND_2023").value("FORM_EL101_MARYLAND_2023").build(),
			SelectOption.builder().title("FORM_IL_8453_ILLINOIS_2023").value("FORM_IL_8453_ILLINOIS_2023").build(),
			SelectOption.builder().title("AZ_8879_2023").value("AZ_8879_2023").build(),
			SelectOption.builder().title("FORM_DR_8454_COLORADO_2023").value("FORM_DR_8454_COLORADO_2023").build(),
			SelectOption.builder().title("FORM_8453_GEORGIA_2023").value("FORM_8453_GEORGIA_2023").build(),
			SelectOption.builder().title("Form PA-8453 2023").value("FORM_8453_PENNSYLVANIA_2023").build(),
			SelectOption.builder().title("Form SC-8453 2021").value("FORM_8453_SOUTH_CAROLINA_2021").build(),
			SelectOption.builder().title("Form KY-F8879-2023").value("KY_F8879_2023").build()
		]
	}

	private remove(): void{
		this.file = null;
		this.payload.fileMetaId = null
	}

	private send(): void {
		ifValid(this, () => {
			this.startLoading();
			IndividualTaxationService.sendAuthForm(this.payload).then(
				() => {
					this.stopLoading();
					this.successful = true
					this.$modal.hideAll()
					this.$router.push({name: RouteNames.ARCHIVE_SIGNABLE_LIST, params: {wsType: this.$wss.getCurrent.type, wsId: this.$wss.getCurrent.id.toString()}})
				},
				err => this.processError(err)
			)
		})
	}

	private processError(err: any) {
		this.successful = false;
		this.message = this.$t(ErrorLocalePathUtils.getPathForError(err)) as string;
		this.stopLoading();
	}
}
